import { SidebarAppsMenuMenu } from "./components/SidebarAppsMenuMenu";
import { SidebarAppsMenuNavLink } from "./components/SidebarAppsMenuNavLink";
import { useMainMenuItems } from "./hooks/useMainMenuItems";

export const SidebarMainMenu = () => {
    const menuItems = useMainMenuItems();

    return (
        <SidebarAppsMenuMenu>
            {menuItems.map((menuItem) => (
                <li key={menuItem.to}>
                    <SidebarAppsMenuNavLink end={menuItem.end} to={menuItem.to}>
                        <menuItem.icon className="mr-2" /> {menuItem.label}
                    </SidebarAppsMenuNavLink>
                </li>
            ))}
        </SidebarAppsMenuMenu>
    );
};
