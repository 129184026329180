import { Dropdown } from "react-daisyui";
import { IconChevronDown, IconLogout, IconUser } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useAuth } from "src/authentication/hooks/useAuth";
import { useAuthenticatedPerson } from "src/authentication/hooks/useAuthenticatedPerson";
import { PersonAvatar } from "src/people/components/PersonAvatar";

interface Props {
    person: ReturnType<typeof useAuthenticatedPerson>;
}

export const ProfileFooter = ({ person }: Props) => {
    const { logout } = useAuth();

    return (
        <Dropdown className="mt-5 p-1 rounded-lg hover:bg-base-200 transition-all">
            <Dropdown.Toggle button={false} className="flex gap-2 items-center active:scale-95 transition-all text-sm">
                <PersonAvatar person={person} size={35} className="self-start" />

                <div className="grow">
                    <p>{person.firstName}</p>
                </div>

                <IconChevronDown className="opacity-50 justify-self-end mr-2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-52 z-10">
                <li role="menuitem">
                    <Link to="/profile">
                        <IconUser className="mr-2" /> My profile
                    </Link>
                </li>

                <li role="menuitem">
                    <button color="ghost" type="button" onClick={logout}>
                        <IconLogout className="mr-2" /> Sign out
                    </button>
                </li>
            </Dropdown.Menu>
        </Dropdown>
    );
};
