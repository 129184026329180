type Nullish<T> = T | null | undefined;

export const classNames = (className: string, conditionalClassNames: Record<string, Nullish<boolean>>) => {
    let result = className;

    for (const conditionalClassName in conditionalClassNames) {
        if (conditionalClassNames[conditionalClassName]) {
            result += ` ${conditionalClassName}`;
        }
    }

    return result;
};
