import { useContext, useMemo } from "react";
import {
    Icon,
    // IconCalendar,
    IconCalendarEvent,
    IconChecklist,
    IconHexagons,
    IconHome,
    IconProgressCheck,
} from "@tabler/icons-react";
import { AbilityContext } from "src/authentication/context/Can";
import { useAuth } from "src/authentication/hooks/useAuth";
import { Action } from "src/casl/Action";

interface MainMenuItem {
    icon: Icon;
    label: string;
    shortLabel?: string;

    to: string;

    bottomNav: boolean;

    end?: boolean;
    bottomEnd?: boolean;
}

/**
 * Constructs a maximum of 5 menu items for the main menu depending on the
 * user's roles.
 */
export const useMainMenuItems = () => {
    const { person } = useAuth();

    const ability = useContext(AbilityContext);

    return useMemo(() => {
        const menuItems: MainMenuItem[] = [
            // Always contains home
            {
                icon: IconHome,
                label: "Home",
                to: "/public",
                end: true,
                bottomEnd: true,
                bottomNav: true,
            },
        ];

        const isVolunteer = ability.can(Action.Read, "Ministry");

        // Event bookings, but only on bottom nav if they aren't a volunteer
        menuItems.push({
            icon: IconCalendarEvent,
            label: "Event & outing bookings",
            shortLabel: "Events & outings",
            to: "/public/event-bookings",
            bottomNav: !isVolunteer,
        });

        // The person is not the responsibility of anyone, so they can access Consent
        if (person?._count.responsiblePeople === 0) {
            menuItems.push({
                icon: IconProgressCheck,
                label: "Consent and medical forms",
                shortLabel: "Forms",
                to: "/public/consent-and-medical",
                bottomNav: !isVolunteer,
            });
        }

        // The person is a volunteer, so they can have a shortcut to their duties page
        if (isVolunteer) {
            menuItems.push({
                icon: IconChecklist,
                label: "My rota",
                to: "/my-rota",
                bottomNav: true,
            });

            // menuItems.push({
            //     icon: IconCalendar,
            //     label: "My calendar",
            //     shortLabel: "Calendar",
            //     to: "/calendar",
            //     bottomNav: true,
            // });

            menuItems.push({
                icon: IconHexagons,
                label: "Ministries",
                shortLabel: "Ministries",
                to: "/ministries",
                bottomNav: true,
                end: true,
            });
        }

        return menuItems;
    }, [person, ability]);
};
