import { RouteObject } from "react-router-dom";
import { peopleRoutes } from "./people/peopleRoutes";
import { ministriesRoutes } from "./ministries/ministriesRoutes";
import { IconChecklist, IconHexagons, IconShieldCheck, IconUsers } from "@tabler/icons-react";
import { childProtectionRoutes } from "./child-protection/childProtectionRoutes";
import { settingsRoutes } from "./settings/settingsRoutes";
import { ErrorScreen } from "./core/components/ErrorScreen";

export const protectedRoutes: RouteObject[] = [
    // Application modules
    { path: "people", children: peopleRoutes, handle: { crumb: "People", icon: IconUsers } },
    { path: "ministries", children: ministriesRoutes, handle: { crumb: "Ministries", icon: IconHexagons } },

    {
        path: "my-rota",
        handle: { crumb: "My Rota", icon: IconChecklist },
        errorElement: (
            <ErrorScreen
                title="This screen's not working right"
                message="Something happened that we couldn't recover from. Try another screen or closing and opening the app again."
            />
        ),
        lazy: () => import("./my-duties/MyDutiesScreen"),
    },

    {
        path: "child-protection",
        children: childProtectionRoutes,
        handle: { crumb: "Child Protection", icon: IconShieldCheck },
    },

    {
        path: "settings",
        children: settingsRoutes,
    },
];
