import { PropsWithChildren, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { CenteredLayout } from "./CenteredLayout";
import { Heading } from "./Heading";
import { RouteBreadcrumbs } from "./RouteBreadcrumbs";
import { NavLink, Navigate, useMatches } from "react-router-dom";
import "./HeadedLayout.css";
import { Icon } from "@tabler/icons-react";
import { classNames } from "../utilities/classNames";

export interface Tab {
    to: string;
    label: string;
    icon?: Icon;
    disabled?: boolean;
    end?: boolean;
}

interface Props {
    heading: ReactNode;

    /** Use to set page title if a complex `heading` is set */
    plainTextHeading?: string;
    headerArea?: ReactNode;
    breadcrumbsArea?: ReactNode;

    contextArea?: ReactNode;

    tabs?: Tab[];

    fullWidth?: boolean;

    hideBreadcrumbs?: boolean;

    footer?: ReactNode;
}

export const goToFirstTab = Symbol("goToFirstTab");

export const HeadedLayout = (props: PropsWithChildren<Props>) => {
    const matches = useMatches();

    const lastMatch = matches[matches.length - 1];

    if (props.tabs && lastMatch && lastMatch.handle === goToFirstTab) {
        return <Navigate to={props.tabs[0].to} replace />;
    }

    return (
        <>
            <header
                className={classNames("bg-base-100 px-4 pb-4 pt-2 lg:pt-5", {
                    "border-b border-b-base-300": !(props.tabs && props.tabs.length > 1),
                })}
            >
                <div className="max-w-screen-lg mx-auto">
                    <div className="flex justify-between items-center">
                        <div>{props.breadcrumbsArea ?? (!props.hideBreadcrumbs && <RouteBreadcrumbs />)}</div>

                        {props.contextArea && <div>{props.contextArea}</div>}
                    </div>

                    {props.heading && <HeadedLayout.Heading>{props.heading}</HeadedLayout.Heading>}

                    {props.headerArea}
                </div>
            </header>

            {props.tabs && props.tabs.length > 1 && (
                <nav className="border-b border-gray-200 dark:border-gray-700 bg-base-100 overflow-x-auto overflow-y-hidden sticky top-0 z-40 no-scrollbar">
                    <ul className="grid grid-flow-col auto-cols-max pl-4 sm:pl-0 -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 max-w-screen-lg justify-center min-w-max mx-auto">
                        {props.tabs.map((tab) => (
                            <li key={tab.to} className="mr-2">
                                {tab.disabled ? (
                                    <span className="inline-flex p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">
                                        {tab.icon && <tab.icon className="mr-2 w-5 h-5" />}
                                        {tab.label}
                                    </span>
                                ) : (
                                    <NavLink
                                        to={tab.to}
                                        end={tab.end}
                                        className={({ isActive }) =>
                                            `inline-flex items-center justify-center p-4 border-b-4 group rounded ${
                                                isActive
                                                    ? "text-primary border-primary dark:text-base-content dark:border-base-content"
                                                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                            }`
                                        }
                                    >
                                        {({ isActive }) => (
                                            <>
                                                {isActive && (
                                                    <Helmet>
                                                        <title>
                                                            {tab.label}
                                                            {(props.plainTextHeading &&
                                                                ` - ${props.plainTextHeading}`) ||
                                                                (typeof props.heading === "string" &&
                                                                    ` - ${props.heading}`)}
                                                        </title>
                                                    </Helmet>
                                                )}
                                                {tab.icon && <tab.icon className="mr-2 w-5 h-5" />}
                                                {tab.label}
                                            </>
                                        )}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            )}

            {(typeof props.heading === "string" || props.plainTextHeading !== undefined) && (
                <Helmet>
                    <title>{props.plainTextHeading ?? props.heading}</title>
                </Helmet>
            )}

            <main className="relative">
                {props.fullWidth ? props.children : <CenteredLayout hideBackLink>{props.children}</CenteredLayout>}
            </main>
        </>
    );
};

interface HeadedLayoutHeadingProps {}

HeadedLayout.Heading = (props: PropsWithChildren<HeadedLayoutHeadingProps>) => (
    <Heading.H1>{props.children}</Heading.H1>
);
