interface Props {
    simple?: boolean;
}

export const LoadingScreen = ({ simple }: Props) => (
    <div role="status" className="bg-base-200 flex justify-center items-center h-screen">
        <div>
            {!simple && (
                <img
                    src="https://www.lurganbaptist.church/wp-content/uploads/2020/04/lbc-logo-mono.png"
                    alt=""
                    className="dark:invert mx-auto h-32"
                />
            )}

            <div className="text-center w-full h-10">
                <span className="loading loading-spinner" />

                <span className="sr-only">Loading...</span>
            </div>
        </div>
    </div>
);
