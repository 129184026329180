import { RouteObject } from "react-router-dom";
import { ministryAdministrationRoutes } from "./ministries/ministryAdministrationRoutes";
import { IconSettings } from "@tabler/icons-react";
import { routeCan } from "src/authentication/routeCan";
import { Action } from "src/casl/Action";

export const settingsRoutes: RouteObject[] = [
    {
        handle: { crumb: "Settings", icon: IconSettings, ...routeCan(Action.Manage, "all") },

        children: [
            { index: true, element: <div>🚧 Settings</div> },

            {
                path: "users",
                children: [{ index: true, lazy: () => import("./users/UsersScreen") }],
            },

            {
                path: "ministries",
                children: ministryAdministrationRoutes,
            },

            {
                path: "calendar-events",
                children: [{ index: true, element: <div>🚧 Calendar Events</div> }],
            },

            {
                path: "vehicles",
                children: [{ index: true, lazy: () => import("./vehicles/VehiclesScreen") }],
            },
        ],
    },
];
