import { Navigate, RouteObject } from "react-router-dom";

export const ministryAdministrationRoutes: RouteObject[] = [
    {
        index: true,
        lazy: () => import("./MinistriesAdminScreen"),
    },

    {
        path: ":ministryId",
        lazy: () => import("./manage-a-ministry/MinistryAdministrationScreen"),

        children: [
            {
                index: true,
                element: <Navigate to="seasons" replace />,
            },

            {
                path: "seasons",
                lazy: () => import("./manage-a-ministry/ministry-seasons/MinistrySeasonsTab"),
            },

            {
                path: "manage-season/:ministrySeasonId",
                lazy: () =>
                    import("./manage-a-ministry/ministry-seasons/view-ministry-season/MinistrySeasonManagementScreen"),

                children: [
                    { index: true, element: <Navigate to="schedule" /> },

                    {
                        path: "schedule",
                        lazy: () =>
                            import(
                                "./manage-a-ministry/ministry-seasons/schedule/MinistrySeasonScheduleManagementScreen"
                            ),
                    },
                    { path: "teams", element: <div>🚧 Teams</div> },
                    { path: "registrants", element: <div>🚧 Registrants</div> },
                    { path: "duties", element: <div>🚧 Duties</div> },
                ],
            },
        ],
    },
];
